<template>
	<edit-template class="page-wrapper white">

    <div class="page-info psychology-page">
      <div class="psychology-h1" v-if="student.student_name">{{ student.student_name }}的咨询结案</div>
      <div class="form-padding">
        <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="140rem" label-position="left" @submit.native.prevent="">
          <el-form-item label="行政班">{{ student.class_name }}</el-form-item>
          <el-form-item label="年级">{{ student.grade_name }}</el-form-item>
          <el-form-item label="校区">{{ student.school_name }}</el-form-item>
          <el-form-item label="咨询总次数">
            <template v-if="conclusion.speed">
              <a href="javascript:void(0)" @click="handleShowHistory">{{ conclusion.speed }} 次</a>
            </template>
            <span v-else="">无</span>
          </el-form-item>
          <el-form-item label="咨询开始时间">{{ conclusion.start_time }}</el-form-item>
          <el-form-item label="咨询结束时间">{{ conclusion.end_time }}</el-form-item>
          <el-form-item label="是否发送反馈表">
            <el-switch v-model="formData.is_feedback"></el-switch>
          </el-form-item>
          <div class="form-item-top-title tips">咨询结束原因</div>
          <el-form-item prop="summary_reason" label-width="0">
            <el-radio-group v-model="formData.summary_reason">
              <el-radio label="来访问题基本解决，双方达成一致结束咨询"></el-radio>
              <el-radio label="学生单方面不再需要咨询"></el-radio>
              <el-radio label="学生已转介到其他老师处"></el-radio>
              <el-radio label="其他"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="remark" label-width="0" style="margin-top: -20rem" v-if="formData.summary_reason === '其他'">
            <el-input v-model="formData.remark" placeholder="请输入咨询结束原因"></el-input>
          </el-form-item>
          <div class="form-item-top-title tips">结束时的状态</div>
          <el-form-item label-width="0" prop="student_status">
            <el-input type="textarea" v-model="formData.student_status" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
          <div class="form-item-top-title tips">咨询过程中的变化</div>
          <el-form-item label-width="0" prop="student_change">
            <el-input type="textarea" v-model="formData.student_change" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
          <div class="form-item-section-title tips">求助者的变化：（最少选填一项）</div>
          <div class="pl-2">
            <div class="form-item-top-title">1.求助者自己的评估</div>
            <el-form-item label-width="0" prop="student_estimate">
              <el-input type="textarea" v-model="formData.student_estimate" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
            </el-form-item>
            <div class="form-item-top-title">2.前后心理测验的比对</div>
            <el-form-item label-width="0" prop="student_contrast">
              <el-input type="textarea" v-model="formData.student_contrast" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
            </el-form-item>
            <div class="form-item-top-title">3.求助者周围人士的评估</div>
            <el-form-item label-width="0" prop="interpersonal_estimate">
              <el-input type="textarea" v-model="formData.interpersonal_estimate" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
            </el-form-item>
            <div class="form-item-top-title">4.社会适应的现实</div>
            <el-form-item label-width="0" prop="social_adaptation">
              <el-input type="textarea" v-model="formData.social_adaptation" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
            </el-form-item>
            <div class="form-item-top-title">5.咨询师的评估</div>
            <el-form-item label-width="0" prop="teacher_estimate">
              <el-input type="textarea" v-model="formData.teacher_estimate" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
            </el-form-item>
          </div>
          <div class="form-item-top-title tips">今后注意事项及建议</div>
          <el-form-item label-width="0" prop="comment">
            <el-input type="textarea" v-model="formData.comment" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
        </el-form>
        <history :visible.sync="isShowHistory" :group_id="formData.group_id" :id="formData.id"></history>
      </div>
    </div>
    <template slot="footer">
      <el-button debounce="" type="primary" @click="submitForm('ruleForm')">提交总结</el-button>
      <el-button debounce="" type="success" @click="submitForm('')">保存草稿</el-button>
      <el-button @click="resetForm('ruleForm')">取消</el-button>
    </template>

</edit-template>
</template>
<script>
import History from '../components/History.vue'
import Photo from '../components/Photo.vue'
import { getApi2, postApi2 } from '@/api'
export default {
	_config:{"route":{"path":"add","meta":{"title":"结案"}}},
  components: { History, Photo },
  data() {
    return {
      autosize: {
        minRows: 5,
        maxRows: 8
      },
      formData: {
        is_feedback: true,
        group_id: 0,
        // id: 0,
        summary_reason: '',
        remark: '',
        student_status: '',
        student_change: '',
        student_estimate: '',
        student_contrast: '',
        interpersonal_estimate: '',
        social_adaptation: '',
        teacher_estimate: '',
        comment: ''
      },
      rules: {
        summary_reason: [{ required: true, message: '请选择咨询结束原因' }],
        remark: [{ required: true, message: '请输入咨询结束原因', trigger: 'change' }],
        student_status: [{ required: true, message: '请输入结束时的状态', trigger: 'change' }],
        student_change: [{ required: true, message: '请输入咨询过程中的变化', trigger: 'change' }],
        student_blur: [{ required: true, message: '请输入咨询过程中的变化', trigger: 'change' }],
        comment: [{ required: true, message: '请输入今后注意事项及建议', trigger: 'change' }]
      },
      isShowHistory: false,
      student: {},
      conclusion: {}
    }
  },
  created() {
    this.formData.group_id = +this.$route.query.group_id
    // this.formData.id = +this.$route.query.id
    this.getDetail()
  },
  methods: {
    getDetail() {
      getApi2('/evaluation/sum-up/done-details', { id: this.formData.group_id }).then(res => {
        const { student, conclusion } = res
        if (student) {
          this.student = student
        }
        if (conclusion) {
          this.conclusion = conclusion
          if(this.$route.query.edit) {
            const { is_feedback, summary_reason, student_status, student_change, student_estimate, student_contrast, interpersonal_estimate, social_adaptation, teacher_estimate, comment } = conclusion
            if(is_feedback !== '') this.formData.is_feedback = is_feedback ? true : false
            if(student_status) this.formData.student_status = student_status
            if(student_change) this.formData.student_change = student_change
            if(student_estimate) this.formData.student_estimate = student_estimate
            if(student_contrast) this.formData.student_contrast = student_contrast
            if(interpersonal_estimate) this.formData.interpersonal_estimate = interpersonal_estimate
            if(social_adaptation) this.formData.social_adaptation = social_adaptation
            if(teacher_estimate) this.formData.teacher_estimate = teacher_estimate
            if(comment) this.formData.comment = comment
            const reasonArr = ['来访问题基本解决，双方达成一致结束咨询', '学生单方面不再需要咨询', '学生已转介到其他老师处']
            if(reasonArr.includes(summary_reason)) {
              this.formData.summary_reason = summary_reason
              return
            }
            if(summary_reason) {
              this.formData.remark = summary_reason
              this.formData.summary_reason = '其他'
            }
          }
        }
      })
    },
    handleShowHistory() {
      this.isShowHistory = true
    },
    submitForm(formName) {
      if(!formName) return this.requestHttp(1)
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          this.requestHttp()
        } else {
          let key = Object.keys(errors)[0]
          this.$message.warning(errors[key][0].message)
          return false
        }
      })
    },
    requestHttp(type) {
      if (!type && (!this.formData.student_estimate && !this.formData.student_contrast && !this.formData.interpersonal_estimate && !this.formData.social_adaptation && !this.formData.teacher_estimate)) {
        this.$message.warning(`求助者的变化最少选填一项!`)
        return
      }
      const { remark, is_feedback, summary_reason, ...other } = this.formData
      let date = {
        ...other,
        is_feedback: is_feedback ? 1 : 0,
        summary_reason: summary_reason === '其他' ? remark : summary_reason
      }
      if(type) {
        date.draft = 1
      }
      postApi2('/evaluation/sum-up/done', date).then(() => {
        this.$router.replace('./list')
        this.$message.success(`操作成功！`)
      })
    },
    resetForm(formName) {
      // this.$refs[formName].resetFields()
      let that = this
      this.$confirm('确定取结案？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$router.back()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-radio {
  margin: 10rem 0 20rem;
  display: block;
}
</style>
